<template>
   <main class="main">
      <app-breadcrumbs :routes="routeData" :last-element="post.title" />

      <section v-if="post.id" class="main__article article">
         <div class="container">
            <h2 class="article__section-title section-title">{{ post.title }}</h2>

            <time :datetime="post.date" class="article__date">{{ post.publicationDate }}</time>

            <div v-html="post.content" class="article__wrap"></div>
         </div>
      </section>

      <app-notifications v-if="!post.id" notification-type="Loading" />

      <app-subscription />
   </main>
</template>

<script>
   import AppBreadcrumbs from '@/components/templates/AppBreadcrumbs';
   import AppSubscription from '@/components/templates/AppSubscription';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { setPublicationDate } from '@/components/_utils/setPublicationDate';
   import { mapState, mapMutations, mapActions } from 'vuex';

   export default {
      name: 'PostPage',

      components: {
         AppBreadcrumbs,
         AppSubscription,
         AppNotifications
      },

      props: {
         id: { type: String, required: true }
      },

      inject: ['backToTopOfPage'],

      data() {
         return {
            routeData: [{ name: 'Blog', query: { page: 1 }, title: 'Блог' }]
         };
      },

      created() {
         this.fetchPost(+this.id);
      },

      beforeUnmount() {
         this.removePost({});
      },

      methods: {
         ...mapMutations('post', { removePost: 'SET_POST' }),
         ...mapActions('post', ['fetchPost'])
      },

      computed: {
         ...mapState('post', ['post', 'errorMessage'])
      },

      watch: {
         errorMessage(error) {
            error?.status === 404 && this.$router.replace({ name: 'NotFound' }); //if
         },

         post(value) {
            value.publicationDate = setPublicationDate(value);

            value.images.list.forEach((item) => {
               const path = require(`@/assets/images/blog/${value.images.folder}/${item}.webp`);

               value.content = value.content.replace(
                  `[img-${item}]`,
                  `<img class="article__img" src="${path}" alt=""/>`
               );
            });

            this.backToTopOfPage();
         }
      }
   };
</script>
