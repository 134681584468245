<template>
   <article class="breadcrumbs main__breadcrumbs">
      <div class="container">
         <h2 class="breadcrumbs__title">Навигация по страницам</h2>

         <ul class="breadcrumbs__list">
            <li v-for="item in breadcrumbs" :key="item.title" class="breadcrumbs__item">
               <router-link
                  :to="{ name: item.name, query: item.query }"
                  :class="{ 'breadcrumbs__link--disabled': item.isLastElement }"
                  class="breadcrumbs__link"
               >
                  {{ item.title }}
               </router-link>

               <span v-if="!item.isLastElement" class="breadcrumbs__transition"></span>
            </li>
         </ul>
      </div>
   </article>
</template>

<script>
   export default {
      name: 'AppBreadcrumbs',

      props: {
         routes: {
            type: Array,
            default() {
               return [];
            }
         },
         lastElement: { type: String, required: true, default: '' }
      },

      data() {
         return {
            homepage: { name: 'Home', title: 'Главная' },
            changedRoutes: []
         };
      },

      computed: {
         breadcrumbs() {
            return this.lastElement
               ? [this.homepage, ...this.routes, { title: this.lastElement, isLastElement: true }]
               : [this.homepage, ...this.changedRoutes];
         }
      },

      watch: {
         lastElement: {
            handler(value) {
               if (!value && this.routes.length) {
                  this.changedRoutes = this.routes.map((item) => ({ ...item }));
                  this.changedRoutes[this.changedRoutes.length - 1].isLastElement = true;
               }
            },
            immediate: true
         }
      }
   };
</script>
